import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Projects = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const content = data.allMarkdownRemark.nodes
  console.log(content)
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Projects" />


      <section className="projects-page">
          <div className="index-projects-section-text">
            <h2>Projects</h2>
            <p>Find out some of Filipe's projects, including all the work done behind the scenes and some thought-provoking details!</p>
            <div>



            <div className="flex-column index-projects-column">
        {content.map(post => {
          const title = post.frontmatter.title || post.fields.slug
          const slug = "../projects" + post.fields.slug
          return (
              <div>
                  <h3>
                    <Link to={slug} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                <Link className="button3" to={slug}>Know more</Link>

              </div>
          )
        })}
      </div>


        </div>
        </div>
        </section>


      
      <section className="index-footer-fmcorreia">
        <footer>
          <div className="index-footer-fmcorreia-text">
            <h2>FMCorreia</h2>
          </div>
        </footer>
      </section>

    </Layout>
  )
}

export default Projects

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: DESC}
      filter: {frontmatter: {category: {eq: "project"}}}
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          category
        }
      }
    }
  }
`
